/* You can add global styles to this file, and also import other style files */

html,
body {
   /* height: 100%;*/
    margin: 0;
    padding: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    /*overflow: -moz-scrollbars-vertical;
    overflow-x: hidden;
    overflow-y: hidden;*/
    height: 100vh;
    
}

.header {
    width: 105%;
    height: 100%;
    left: -3%;
    position: absolute;
    background-color: #e43779;
    background-size: 100px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 90% 50%;
    border-bottom-right-radius: 90% 50%;
    text-align: center;
    color: #fff;
    padding-top: 10px;
}

.header-2 {
    width: 105%;
    height: 400px;
    left: -3%;
    position: absolute;
    background-color: #e43779;
    background-size: 100px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 90% 50%;
    border-bottom-right-radius: 90% 50%;
    text-align: center;
    color: #fff;
    padding-top: 10px;
}

.container-logo {
    width: 100%;
    height: 300px;
    background-color: white;
    position: relative;
    overflow: hidden;
}

.container-logo-1 {
    width: 100%;
    height: 200px;
    background-color: #e43779;
    position: relative;
    overflow: hidden;
}


.heading-title,
.trans-comment {
    color: #e43779;
    padding: 10px;
    padding-bottom: 0.2em;
}

.trans-comment-repay {
    color: #e43779;
    padding: 10px;
}

.heading {
    color: #e43779;
}

.heading-t1 {
    font-weight: 800;
}

.heading-t2 {
    font-weight: 400!important;
    /*margin-top: 2.8rem!important;*/
    margin-top: 1.8rem!important;
}

.heading-label {
    font-weight: 500;
    font-size: 1.7rem;
}

.form-inputs {
    border-radius: 10px !important;
    border: 1px solid #e43779 !important;
}

.form-btn {
    border-radius: 10px!important;
    border-color: #e43779!important;
    background-color: #e43779!important;
    color: #fff!important;
}

.form-btn-no-bg {
    border-radius: 8px!important;
    border: 1px solid #e43779!important;
    color: #e43779!important;
    font-weight: 500!important;
}

.curve-bg {
    border-top-left-radius: 3.2em 2.5em;
    border-top-right-radius: 3.2em 2.5em;
    background-color: #fff;
    position: relative;
    top: -6rem;
    overflow: hidden;
}

.mat-slider {
    height: 300px;
    width: 100px;
}

.date-lable {
    color: #e43779;
    font-weight: bold;
    padding: 6px;
}

hr.new1 {
    border-top: 1px solid #e43779;
    width: 100%;
}

.trans-box {
    border: 0px solid #ccc;
    border-radius: 12px;
}

.trans-box-1 {
    border: 1px solid #ccc;
    border-radius: 6px;
}

.succes-trans {
    color: rgb(74, 226, 74);
}

.failed-trans {
    color: rgb(240, 58, 34);
}

.trans-label {
    color: rgb(25, 31, 25);
}

.trans-input {
    color: #e43779;
    font-weight: 500;
}

.amount_label {
    color: #e43779;
    font-weight: 500;
    font-size: 1.5rem;
    background-color: #e5e0f5;
    /*padding: 0.6rem;*/
    width: 50%;
    border-radius: 6px;
}

.mat-bottom-sheet-container {
    border-top-left-radius: 4em 4em!important;
    border-top-right-radius: 4em 4em!important;
    overflow: hidden!important;
}

.mat-slider.mat-slider-horizontal .mat-slider-wrapper {
    top: 18px;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
    height: 8px;
    border-radius: 10px
}

.mat-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
    height: 100%;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
    background-color: #e43779;
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
    background-color: white;
}

.back-arrow {
    position: relative;
    top: 2.4em;
    left: 1.5em;
    font-size: 1.1rem;
}

.mtt-2 {
    padding: null;
}

.custom-m
{
    margin-top: 2.5rem;
}

.trans-details{height:64vh;overflow-y:scroll;} 
  

  .trans-details::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.trans-details {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.limit-value{
    font-size: 1rem;
    font-weight: 500;
}

.limit-title{
    font-size: 0.9rem;
}

.logo-img
{
    height: 40px;
    width: 30px;
    padding-top: 20px;
}

.due-box
{
    background-color: #fce8d5;
    border-radius: 15px;
   /* padding: 10px 20px 20px 20px;*/
}
/* set classes for responsive design */

@media only screen and (max-width: 420px){

.logo-img
{
    height: 65px;
    width: 30px;
}
.heading-title
{
    font-size: 12px;
}
.container-logo
{
    height: 250px;
}
.container-logo-1
{
    height: 100px;
}
.header-2
{
    
    height: 100px;
}
.curve-bg {
    
    top: -1.6rem;
  
}
}

input[type="range"] {
    -webkit-appearance: none;
    margin-right: 15px;
    width: 300px;
    height: 7px;
    background: rgba(160, 160, 160, 0.6);
    border-radius: 50%/100px 100px 0 0;
    background-image: linear-gradient(#e43779, #e43779);
    background-size: 0% 0%;
    background-repeat: no-repeat;
    
  }
  
  /* Input Thumb */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #e43779;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
    
  }
  
  input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #e43779;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
   
  }
  
  input[type="range"]::-ms-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #e43779;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
   
  }
  
  input[type="range"]::-webkit-slider-thumb:hover {
    background: #e43779;
  }
  
  input[type="range"]::-moz-range-thumb:hover {
    background: #e43779;
  }
  
  input[type="range"]::-ms-thumb:hover {
    background: #e43779;
  }
  
  /* Input Track */
  input[type=range]::-webkit-slider-runnable-track  {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
  
  input[type=range]::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
  
  input[type="range"]::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
/*input[type="range"] { 
    margin: auto;
    -webkit-appearance: none;
    position: relative;
    overflow: hidden;
    height: 0.9rem;
    width: 300px;
    cursor: pointer;
    border-radius:  14px; 
}

::-webkit-slider-runnable-track {
    background: #ddd;
}


::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1rem;
    height:1rem;
    border-radius: 50%;
    background: #4c3196;
    box-shadow: -100vw -10vw 0 100vw #4c3196;
    border: 1px solid #fff;
   
}

::-moz-range-track {
    height: 1.25rem;
    background: #ddd;
}

::-moz-range-thumb {
    -webkit-appearance: none;
    width: 0.99rem;
    height: 0.99rem;
    border-radius: 50%;
    background: #4c3196;
    box-shadow: -100vw -10vw 0 100vw #4c3196;
    border: 1px solid #999;
}

::-ms-fill-lower { 
    background: #4c3196;
}

::-ms-thumb { 
    -webkit-appearance: none;
    width: 0.99rem;
    height: 0.99rem;
    border-radius: 50%;
    background: #4c3196;
    box-shadow: -100vw -10vw 0 100vw #4c3196;
    border: 1px solid #999;
}

::-ms-ticks-after { 
    display: none; 
}

::-ms-ticks-before { 
    display: none; 
}

::-ms-track { 
    background: #ddd;
    color: transparent;
    height: 1.25rem;
    border: none;
}

::-ms-tooltip { 
    display: none;
}*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .limit-value {
        font-size: 0.8rem;
    }
    .limit-title{
        font-size: 0.8rem;
    }
    input[type="range"] { 
       
        width: 250px;
       
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .res-mt-4
    {
        margin-top: 1.5rem;
    }
    .res-p-4
    {
        padding: 1.5rem;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
   
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}
